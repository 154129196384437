<div>
  <iframe
    *ngIf="isGoogleServicesContent"
    width="100%"
    [height]="mapHeight"
    style="border: 0"
    loading="lazy"
    allowfullscreen
    [src]="safeUrl"
  >
  </iframe>

  <div *ngIf="!isGoogleServicesContent">
    <div class="google-container">
      <img src="/gematik-shared/assets/icons/googleMaps.png" width="100%" style="opacity: 0.2" />
      <div class="google-centered">
        <span>
          {{ "gem-components.cookie-settings.google.text-1" | translate }}
          <span style="cursor: pointer" (click)="onEditCookies()">
            <strong>{{ "gem-components.cookie-settings.google.text-2" | translate }}</strong> </span
          >{{ "gem-components.cookie-settings.google.text-3" | translate }}
        </span>
      </div>
    </div>
  </div>
</div>
