import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { EnvService } from "gematik-form-library";
import { AddressDto, GematikProfile, PartnerDto, PartnerRoleDto } from "../../models/profile.model";
import { AssignmentGroupIdentityExtendedDto } from "../../models/profile.model";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  constructor(private http: HttpClient, private env: EnvService) {}

  getProfile(): Observable<GematikProfile> {
    return this.http.get<GematikProfile>(`${this.env.backendUrl}/portal/profile`);
  }

  updateProfile(payload: GematikProfile): Observable<GematikProfile> {
    return this.http.post<GematikProfile>(`${this.env.backendUrl}/portal/profile`, payload);
  }

  deleteProfile(): Observable<any> {
    return this.http.delete(`${this.env.backendUrl}/portal/profile`);
  }

  getAssignmentGroupIdentities(): Observable<AssignmentGroupIdentityExtendedDto[]> {
    return this.http.get<AssignmentGroupIdentityExtendedDto[]>(
      `${this.env.backendUrl}/portal/profile/assignment-group-identities`,
    );
  }

  getAssignmentGroupIdentityAddresses(id: string): Observable<AddressDto[]> {
    return this.http.get<AddressDto[]>(
      `${this.env.backendUrl}/portal/profile/assignment-group-identities/${id}/addresses`,
    );
  }

  addAssignmentGroupIdentityAddress(agiId: string, address: AddressDto): Observable<AddressDto> {
    return this.http.post<AddressDto>(
      `${this.env.backendUrl}/portal/profile/assignment-group-identities/${agiId}/addresses`,
      address,
    );
  }

  updateAssignmentGroupIdentityAddress(agiId: string, address: AddressDto): Observable<AddressDto> {
    return this.http.put<AddressDto>(
      `${this.env.backendUrl}/portal/profile/assignment-group-identities/${agiId}/addresses/${address.id}`,
      address,
    );
  }

  deleteAssignmentGroupIdentityAddress(id: string): Observable<AddressDto> {
    return this.http.delete<AddressDto>(
      `${this.env.backendUrl}/portal/profile/assignment-group-identities/addresses/${id}`,
    );
  }

  getAssignmentGroupAddresses(id: string): Observable<AddressDto[]> {
    return this.http.get<AddressDto[]>(
      `${this.env.backendUrl}/portal/profile/assignment-groups/${id}/addresses`,
    );
  }

  addAssignmentGroupAddress(agId: string, address: AddressDto): Observable<AddressDto> {
    return this.http.post<AddressDto>(
      `${this.env.backendUrl}/portal/profile/assignment-groups/${agId}/addresses`,
      address,
    );
  }

  updateAssignmentGroupAddress(agId: string, address: AddressDto): Observable<AddressDto> {
    return this.http.put<AddressDto>(
      `${this.env.backendUrl}/portal/profile/assignment-groups/${agId}/addresses/${address.id}`,
      address,
    );
  }

  deleteAssignmentGroupAddress(id: string): Observable<AddressDto> {
    return this.http.delete<AddressDto>(
      `${this.env.backendUrl}/portal/profile/assignment-groups/addresses/${id}`,
    );
  }

  getPartner(orgId: string): Observable<PartnerDto> {
    return this.http.get<PartnerDto>(`${this.env.backendUrl}/portal/profile/partners/${orgId}`);
  }

  updatePartner(partner: PartnerDto): Observable<PartnerDto> {
    return this.http.put<PartnerDto>(
      `${this.env.backendUrl}/portal/profile/partners/${partner.id}`,
      partner,
    );
  }

  getPartnerRoles(orgId: string): Observable<PartnerRoleDto[]> {
    return this.http.get<PartnerRoleDto[]>(
      `${this.env.backendUrl}/portal/profile/partners/${orgId}/partner-roles`,
    );
  }
}
