import { Component, inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { TranslateService } from "@ngx-translate/core";
import { CookieHelperService } from "../../helper/cookie-helper.service";
import { EnvService } from "../../helper/env.service";

@Component({
  selector: "gem-cookie-settings",
  styleUrls: ["gem-cookie-settings.component.scss"],
  template: `
    <div class="p-4">
      <div class="d-flex">
        <div class="flex-grow-1">{{ "gem-components.cookie-settings.title" | translate }}</div>
        <div>
          <a (click)="onLangChange()" class="d-flex nav-link link-white px-2">
            <span data-testid="lang" (click)="onLangChange()" class="material-icons me-2">
              language
            </span>
            {{ lang === "de" ? "English" : "Deutsch" }}
          </a>
        </div>
      </div>
      <div class="d-flex mt-3">
        <span
          [innerHTML]="'gem-components.cookie-settings.text' | translate : { privacyPolicyLink }"
        ></span>
      </div>

      <div class="d-flex mt-3">
        <div class="d-flex" [formGroup]="form">
          <div class="form-check form-switch me-3">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="cookiesSwitch"
              formControlName="cookies"
              disabled
            />
            <label class="form-check-label" for="cookiesSwitch">{{
              "gem-components.cookie-settings.options.essential" | translate
            }}</label>
          </div>
          <div class="form-check form-switch me-3">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="youtubeSwitch"
              formControlName="youtubeServices"
            />
            <label class="form-check-label" for="youtubeSwitch">{{
              "gem-components.cookie-settings.options.youtube" | translate
            }}</label>
          </div>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              role="switch"
              id="googleSwitch"
              formControlName="googleServices"
            />
            <label class="form-check-label" for="googleSwitch">{{
              "gem-components.cookie-settings.options.google" | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-primary" (click)="onSaveSettings()">
          {{ "gem-components.cookie-settings.buttons.save" | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="onAcceptAll()">
          {{ "gem-components.cookie-settings.buttons.accept" | translate }}
        </button>
        <button type="button" class="btn btn-primary" (click)="onDeny()">
          {{ "gem-components.cookie-settings.buttons.deny" | translate }}
        </button>
      </div>
    </div>
  `,
})
export class GemCookieSettingsComponent implements OnInit {
  // private _bottomSheetRef =
  //   inject<MatBottomSheetRef<GemCookieSettingsComponent>>(MatBottomSheetRef);

  form: FormGroup = this.fb.group({
    cookies: [true],
    googleServices: [false],
    youtubeServices: [false],
  });

  constructor(
    private bottomSheetRef: MatBottomSheetRef<GemCookieSettingsComponent>,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private cookieHelperService: CookieHelperService,
    private env: EnvService,
  ) {}

  ngOnInit(): void {
    this.cookies.disable();
    this.form.patchValue(this.cookieHelperService.getCookieSettings());
  }

  onSaveSettings(): void {
    this.cookieHelperService.setCookieSettings(this.form.getRawValue());
    this.bottomSheetRef.dismiss();
  }

  onAcceptAll(): void {
    this.googleServices.setValue(true);
    this.youtubeServices.setValue(true);
    this.cookieHelperService.setCookieSettings(this.form.getRawValue());
    this.bottomSheetRef.dismiss();
  }

  onDeny(): void {
    this.googleServices.setValue(false);
    this.youtubeServices.setValue(false);
    this.cookieHelperService.setCookieSettings(this.form.getRawValue());
    this.bottomSheetRef.dismiss();
  }

  onLangChange(): void {
    if (this.translateService.currentLang === "de") {
      this.translateService.use("en");
      localStorage.setItem("gem-locale", "en");
    } else {
      this.translateService.use("de");
      localStorage.setItem("gem-locale", "de");
    }
  }

  get lang(): string {
    return this.translateService.currentLang;
  }

  get cookies(): FormControl {
    return this.form.get("cookies") as FormControl;
  }

  get googleServices(): FormControl {
    return this.form.get("googleServices") as FormControl;
  }

  get youtubeServices(): FormControl {
    return this.form.get("youtubeServices") as FormControl;
  }

  get privacyPolicyLink(): string {
    return `${this.env.uwlUrl}/data-privacy`;
  }
}
