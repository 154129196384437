import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, Validators } from "@angular/forms";
import { Feedback } from "../../models/feedback.model";
import { TranslateService } from "@ngx-translate/core";
import { EnvService, GemValidators, TokenHelperService } from "gematik-form-library";
import { Subscription } from "rxjs";

@Component({
  selector: "gem-feedback-general-form",
  styleUrls: ["gem-feedback-general-form.component.scss"],
  template: `
    <form [formGroup]="form">
      <div class="row mb-3">
        <div class="col-sm-12 mt-3">
          <p>{{ "feedback.generalRating" | translate }}</p>
          <ngb-rating [max]="5" formControlName="rating" class="rating"></ngb-rating>
        </div>
        <div class="col-sm-12 mt-3">
          <gem-textarea
            [placeholder]="'feedback.generalComments' | translate"
            [control]="this.form.get('comments')"
            [maxChars]="500"
            [hint]="
              translateService.instant('feedback.commentsHint') +
              ' (' +
              this.form.get('comments').value.length +
              ' / 500' +
              ')'
            "
          ></gem-textarea>
        </div>

        <div class="col-sm-12 mt-3">
          <p>{{ "feedback.generalContact" | translate }}</p>
          <gem-radio-button-group-vertical
            [valueLabels]="radioButtonOptions"
            [control]="this.form.get('contact')"
          ></gem-radio-button-group-vertical>
        </div>

        <div class="col-sm-12 mt-3">
          <p>
            {{
              emailIsRequired
                ? ("feedback.generalEmailRequired" | translate)
                : ("feedback.generalEmailOptional" | translate)
            }}
          </p>
          <gem-input
            [placeholder]="'email' | translate"
            [hint]="'feedback.emailHint' | translate"
            [control]="email"
          >
          </gem-input>
        </div>

        <div *ngIf="!isLoggedIn" class="col-sm-12 mt-3">
          <div class="checkbox-container disable-checkbox">
            <mat-checkbox [formControl]="dataPrivacy" [color]="'primary'">
              <span style="cursor: default" [innerHTML]="dataPrivacyLabel"></span>
            </mat-checkbox>
          </div>
        </div>

        <div class="col-sm-12 text-end mt-3">
          <button
            type="button"
            class="btn btn-tertiary"
            style="display: inline-flex"
            (click)="onBack()"
          >
            <mat-icon class="me-2">chevron_left</mat-icon>
            {{ "feedback.back" | translate }}
          </button>

          <button
            type="button"
            class="mat-focus-indicator btn btn-primary mat-button mat-button-base"
            style="display: inline-flex"
            cdkFocusInitial
            [disabled]="!isFormValid()"
            (click)="onSubmit($event)"
          >
            {{ "feedback.send" | translate }}
            <mat-icon iconPositionEnd class="ms-2">send</mat-icon>
          </button>
        </div>
      </div>
    </form>
  `,
})
export class GemFeedbackGeneralFormComponent implements OnInit, OnDestroy {
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() feedback: EventEmitter<{ feedback: Feedback; documents: any[] }> = new EventEmitter();

  form = this.fb.group({
    category: ["GENERAL", Validators.required],
    rating: [undefined, Validators.required],
    comments: [""],
    contact: ["ANONYMOUS"],
    email: [undefined],
    dataPrivacy: [false, Validators.requiredTrue],
  });

  radioButtonOptions = [
    { value: "ANONYMOUS", label: this.translateService.instant("feedback.radioButtonAnonymous") },
    { value: "WITH_EMAIL", label: this.translateService.instant("feedback.radioButtonWithEmail") },
  ];

  subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public translateService: TranslateService,
    private tokenHelperService: TokenHelperService,
    private env: EnvService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.form.get("contact").valueChanges.subscribe((change) => {
      if (change === "WITH_EMAIL") {
        this.form.get("email").addValidators([Validators.required, GemValidators.email]);
        this.form.get("email").updateValueAndValidity();
        if (this.tokenHelperService.isLoggedIn()) {
          this.form.get("email").setValue(this.tokenHelperService.getEmail());
        }
      } else {
        this.form.get("email").clearValidators();
        this.form.get("email").updateValueAndValidity();
        this.form.get("email").reset();
      }
    });
    if (this.tokenHelperService.isLoggedIn()) {
      this.dataPrivacy.setValue(true);
    } else {
      this.dataPrivacy.setValue(false);
    }
  }

  isFormValid(): boolean {
    return this.form.valid;
  }

  onBack(): void {
    this.back.emit();
  }

  onSubmit(event: any): void {
    const feedback: Feedback = this.form.getRawValue();
    this.feedback.emit({ feedback: feedback, documents: [] });
    event.target.disabled = true;
  }

  get email(): FormControl {
    return this.form.get("email") as FormControl;
  }

  get emailIsRequired(): boolean {
    return this.email.hasValidator(Validators.required);
  }

  get dataPrivacy(): FormControl {
    return this.form.get("dataPrivacy") as FormControl;
  }

  get isLoggedIn(): boolean {
    return this.tokenHelperService.isLoggedIn();
  }

  get dataPrivacyLabel(): string {
    return this.translateService.instant("feedback.dataPrivacy", {
      link: `${this.env.uwlUrl}/data-privacy`,
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
