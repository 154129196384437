import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Feedback } from "../../models/feedback.model";
import { FormArray, FormBuilder, FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { EnvService, GemValidators, TokenHelperService } from "gematik-form-library";

@Component({
  selector: "gem-feedback-bug-form",
  styleUrls: ["gem-feedback-bug-form.component.scss"],
  template: `
    <form [formGroup]="form">
      <div class="row mb-3">
        <div class="col-sm-12 mt-3">
          <p>{{ "feedback.bugComments" | translate }}</p>
          <gem-textarea
            [placeholder]="'feedback.bugCommentsPlaceholder' | translate"
            [control]="this.form.get('comments')"
            [maxChars]="500"
            [hint]="
              translateService.instant('feedback.commentsHint') +
              ' (' +
              this.form.get('comments').value.length +
              ' / 500' +
              ')'
            "
          ></gem-textarea>
        </div>

        <div class="col-sm-12 mt-3">
          <p>{{ "feedback.bugDocuments" | translate }}</p>
          <gem-file-drop-zone
            data-testid="documents"
            [outlined]="false"
            [label]="'gem-components.gem-file-upload.label' | translate"
            [allowedExtensions]="['.pdf']"
            [multiple]="true"
            [files]="documents"
            (upload)="onFileUpload($event, false)"
            (remove)="onRemoveFile($event)"
          ></gem-file-drop-zone>
        </div>

        <div class="col-sm-12 mt-3">
          <p>{{ "feedback.bugEmail" | translate }}</p>
          <gem-input
            [placeholder]="'email' | translate"
            [hint]="'feedback.emailHint' | translate"
            [control]="this.form.get('email')"
          >
          </gem-input>
        </div>

        <div *ngIf="!isLoggedIn" class="col-sm-12 mt-3">
          <div class="checkbox-container disable-checkbox">
            <mat-checkbox [formControl]="dataPrivacy" [color]="'primary'">
              <span style="cursor: default" [innerHTML]="dataPrivacyLabel"></span>
            </mat-checkbox>
          </div>
        </div>

        <div class="col-sm-12 text-end mt-3">
          <button
            type="button"
            class="btn btn-tertiary"
            style="display: inline-flex"
            (click)="onBack()"
          >
            <mat-icon class="me-2">chevron_left</mat-icon>
            {{ "feedback.back" | translate }}
          </button>

          <button
            class="mat-focus-indicator btn btn-primary mat-button mat-button-base"
            style="display: inline-flex"
            cdkFocusInitial
            type="button"
            [disabled]="!isFormValid()"
            (click)="onSubmit($event)"
          >
            {{ "feedback.send" | translate }}
            <mat-icon iconPositionEnd class="ms-2">send</mat-icon>
          </button>
        </div>
      </div>
    </form>
  `,
})
export class GemFeedbackBugFormComponent implements OnInit {
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() feedback: EventEmitter<{ feedback: Feedback; documents: any[] }> = new EventEmitter();

  form = this.fb.group({
    category: ["BUG", Validators.required],
    rating: [1],
    comments: ["", Validators.required],
    contact: ["WITH_EMAIL"],
    email: ["", [Validators.required, GemValidators.email]],
    documents: this.fb.array([]),
    dataPrivacy: [false, Validators.requiredTrue],
  });

  radioButtonOptions = [
    { value: "ANONYMOUS", label: this.translateService.instant("feedback.radioButtonAnonymous") },
    { value: "WITH_EMAIL", label: this.translateService.instant("feedback.radioButtonWithEmail") },
  ];

  constructor(
    private fb: FormBuilder,
    public translateService: TranslateService,
    private tokenHelperService: TokenHelperService,
    private env: EnvService,
  ) {}

  ngOnInit(): void {
    if (this.tokenHelperService.isLoggedIn()) {
      this.form.get("email").setValue(this.tokenHelperService.getEmail());
    }
    if (this.tokenHelperService.isLoggedIn()) {
      this.dataPrivacy.setValue(true);
    } else {
      this.dataPrivacy.setValue(false);
    }
  }

  isFormValid(): boolean {
    return this.form.valid;
  }

  onFileUpload(event: FileList, multiple: boolean) {
    if (event) {
      this.documents.clear();
      Array.from(event).forEach((file: File) => {
        this.documents.push(
          this.fb.group({
            id: [null],
            fileName: [file.name],
            file: [file],
          }),
        );
      });
    }
  }

  onRemoveFile(index: number): void {
    this.documents.removeAt(index);
  }

  onBack(): void {
    this.back.emit();
  }

  onSubmit(event: any): void {
    const feedback: Feedback = this.form.getRawValue();
    this.feedback.emit({ feedback: feedback, documents: this.documents.value });
    event.target.disabled = true;
  }

  get documents(): FormArray {
    return this.form.get("documents") as FormArray;
  }

  get dataPrivacy(): FormControl {
    return this.form.get("dataPrivacy") as FormControl;
  }

  get isLoggedIn(): boolean {
    return this.tokenHelperService.isLoggedIn();
  }

  get dataPrivacyLabel(): string {
    return this.translateService.instant("feedback.dataPrivacy", {
      link: `${this.env.uwlUrl}/data-privacy`,
    });
  }
}
