import { Injectable } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { CookieService } from "ngx-cookie-service";
import { GemCookieSettingsComponent } from "../gem-components/gem-cookie-settings/gem-cookie-settings.component";
import { BehaviorSubject } from "rxjs";

export interface GemCookie {
  cookies: boolean;
  googleServices: boolean;
  youtubeServices: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CookieHelperService {
  private _cookieName: string = "gem-cookie-settings";
  private _cookieChanges$ = new BehaviorSubject(undefined);

  constructor(private cookieService: CookieService, private bottomSheet: MatBottomSheet) {}

  isCookiesConsent(): boolean {
    return this.getCookieSettings().cookies;
  }

  isGoogleServicesContent(): boolean {
    return this.getCookieSettings().googleServices;
  }

  isYoutubeServicesContent(): boolean {
    return this.getCookieSettings().youtubeServices;
  }

  setCookieSettings(value: any): void {
    this.cookieService.set(this._cookieName, JSON.stringify(value));
    this._cookieChanges$.next(undefined);
  }

  getCookieSettings(): GemCookie {
    let cookie: GemCookie = {
      cookies: true,
      googleServices: false,
      youtubeServices: false,
    };
    if (this.cookieService.check(this._cookieName)) {
      cookie = JSON.parse(this.cookieService.get(this._cookieName));
    }
    return cookie;
  }

  openCookieSettings(check: boolean, disableClose: boolean): void {
    if (check) {
      if (!this.cookieService.check(this._cookieName)) {
        this.openCookieSettingsBottomSheet(disableClose);
      }
    } else {
      this.openCookieSettingsBottomSheet(disableClose);
    }
  }

  getCookieChanges() {
    return this._cookieChanges$.asObservable();
  }

  private openCookieSettingsBottomSheet(disableClose: boolean): void {
    this.bottomSheet.open(GemCookieSettingsComponent, {
      disableClose,
    });
  }
}
