<div class="row">
  <div class="col-sm-12">
    <h1>{{ title | translate }}</h1>
  </div>
  <div class="col-sm-12">
    <span *ngIf="taskTarget === 'group'">{{ "portal.tasks.group.subTitle" | translate }}</span>
    <span *ngIf="taskTarget === 'my'">{{ "portal.tasks.my.subTitle" | translate }}</span>
  </div>
</div>
<div class="mt-4"></div>
<div class="row">
  <div class="col-sm-10">
    <form [formGroup]="form">
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          id="searchInput"
          placeholder="Suche"
          formControlName="searchStr"
        />
        <label for="searchInput">{{ "portal.fields.search.label" | translate }}</label>
      </div>
    </form>
  </div>
  <div class="col-sm-2"></div>
</div>
<div class="mt-3"></div>
<div class="row">
  <div class="col-sm-12">
    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        (click)="onToggleView()"
        [checked]="!activeTab"
        id="viewSwitch"
      />
      <label class="form-check-label" for="viewSwitch">{{
        "portal.tasks.fields.closed.label" | translate
      }}</label>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <task-table
      [ngStyle]="{ display: activeTab ? 'block' : 'none' }"
      #taskTable
      [tasks]="openTasks$ | async"
      [type]="type"
      [searchStr]="searchStr.value"
      (taskSelected)="openFormForTask($event, taskTarget)"
      (taskCompleted)="refreshTasks($event)"
      (clearFilter)="onClearFilter($event)"
    >
    </task-table>
    <completed-task-table
      [ngStyle]="{ display: activeTab ? 'none' : 'block' }"
      [completedTasks]="completedTasks"
    ></completed-task-table>
  </div>
</div>
