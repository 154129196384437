import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { AuthConfig, OAuthEvent, OAuthService } from "angular-oauth2-oidc";
import { NavigationEnd, Router } from "@angular/router";
import { CookieHelperService, EnvService } from "gematik-form-library";
import { TokenHelperService } from "gematik-form-library";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { SidenavService } from "./services/sidenav.service";
import { delay } from "rxjs/operators";
import { Endpoint } from "./services/uwl/uwl.service";
import { GematikTaskApiService, GematikTranslationDto } from "gematik-task-api";
import { RxStompService } from "./services/stomp/rx-stomp.service";
import { Message } from "@stomp/stompjs";

import * as fromStore from "./store";
import * as fromSharedStore from "gematik-shared";
import { Store } from "@ngrx/store";
import { NgbOffcanvas } from "@ng-bootstrap/ng-bootstrap";
import { Alert, PortalService, LoadingService } from "gematik-shared";
import { ReactiveFormConfig } from "@rxweb/reactive-form-validators";
import { GuidedTourService } from "ngx-guided-tour";
import { PortalGuidedTourService } from "./services/portal-guided-tour.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("sidenav") sidenav: MatSidenav;
  @ViewChild("container") container: ElementRef;
  isHighRes: Observable<BreakpointState> = this.breakpointObserver.observe([
    Breakpoints.XLarge,
    Breakpoints.Large,
  ]);
  isHighResMode: boolean;
  handsetBreakpoint: string = "540px";
  handsetBreakpointMatched: boolean = false;
  menuIcon = "chevron_left";
  actualComponentRef: any;
  isAuthorized: Observable<boolean>;

  alerts$: Observable<Alert[]>;

  // Subscriptions
  subscriptions: Subscription[] = [];

  isLoading$: Observable<boolean>;

  isExpanded: boolean = false;
  isHome: boolean = true;

  constructor(
    private translationService: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private oauthService: OAuthService,
    private router: Router,
    private env: EnvService,
    private tokenHelper: TokenHelperService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sidenavService: SidenavService,
    private tokenHelperService: TokenHelperService,
    private taskService: GematikTaskApiService,
    private rxStompService: RxStompService,
    private store: Store<fromStore.UwlState>,
    private loadingService: LoadingService,
    private offcanvasService: NgbOffcanvas,
    private portalService: PortalService,
    private guidedTourService: GuidedTourService,
    private portalGuidedTourService: PortalGuidedTourService,
    private cookieHelperService: CookieHelperService,
  ) {
    ReactiveFormConfig.set({
      internationalization: {
        dateFormat: "dmy",
        seperator: "/",
      },
    });

    if (this.tokenHelperService.isExpired()) {
      this.tokenHelperService.logout();
    }

    const authConfig: AuthConfig = {
      issuer: "https://login.microsoftonline.com/" + env.oauthTennantId + "/v2.0",
      redirectUri: window.location.origin + "/oauth",
      clientId: env.oauthClientId,
      responseType: "code",
      clearHashAfterLogin: false,
      strictDiscoveryDocumentValidation: false,
      scope: "openid " + env.oauthApplicationIdUri + "/user_impersonation",
    };
    this.oauthService.configure(authConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
      switch (type) {
        case "token_received":
          tokenHelper.saveToken(this.oauthService.getAccessToken());
          this.router.navigate([decodeURIComponent(this.oauthService.state)]);
          this.initStore();
          break;
        case "code_error":
          tokenHelper.saveToken("");
          this.router.navigate(["/noaccess"]);
          break;
      }
    });

    const lang = localStorage.getItem("gem-locale");
    if (lang) {
      if (lang === "en") {
        translationService.use("en");
      } else {
        translationService.use("de");
      }
    } else {
      translationService.use("de");
    }
    this.matIconRegistry.addSvgIcon(
      "gem-users",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/gem-users.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "settings",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/settings.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "admin-panel-settings",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/admin-panel-settings.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "processes",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/processes.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "signpost",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/signpost.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-approval-blue",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/Zustimmung_Blau_gematik.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "unclaim",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/unclaim.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "caseLog",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/case-log.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-refresh",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/refresh.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-feedback",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/gem-feedback.svg"),
    );

    // portal icons
    this.matIconRegistry.addSvgIcon(
      "gem-dashboard",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/dashboard.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-report",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/report.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-mediation",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/mediation.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-people-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/people-outline.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-logout",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/logout.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-content-paste",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/content_paste.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-in-progress",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/in-progress.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-notifications-active",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/portal/notifications_active.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-done",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/done.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/edit.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/logo.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-logo-small",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/logo-small.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-miscellaneous-services",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/icons/portal/miscellaneous_services.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-save",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/save.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-cancel",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/cancel.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-flash-on",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/flash_on.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-timeline",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/timeline.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-open-in-new",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/open_in_new.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-close",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/gematik-shared/assets/icons/close.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-toggler",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/gematik-shared/assets/icons/toggler.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-translate-blue",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/gematik-shared/assets/icons/portal/translate-blue.svg",
      ),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-logo-flag",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/logo-flag.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-favourite",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/favourite.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "gem-tabs",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/portal/tabs.svg"),
    );
  }

  ngOnInit() {
    this.cookieHelperService.openCookieSettings(true, true);

    this.alerts$ = this.portalService.getAlerts(this.env.backendUrl, "UWL");
    if (this.tokenHelperService.isLoggedIn()) {
      this.initStore();
    }
    const sub = this.breakpointObserver
      .observe(["(max-width: 880px)", `(max-width: ${this.handsetBreakpoint})`])
      .subscribe((state) => {
        const breakpointMatched: boolean = state.breakpoints[`(max-width: 880px)`];
        this.handsetBreakpointMatched = state.breakpoints[`(max-width: ${this.handsetBreakpoint})`];
        if (breakpointMatched) {
          this.isExpanded = false;
        } else {
          this.isExpanded = true;
        }
      });
    this.subscriptions.push(sub);

    window.addEventListener("wheel", (event) => this.doNothing());

    const rxStompSub = this.rxStompService.watch("/topic/gematik").subscribe((message: Message) => {
      const body = JSON.parse(message.body);
      const type = body.type;
      if (type !== "QUICK_ACTION") {
        const eventName = body.event.eventName;
        if (type === "INSTANCE") {
          this.store.dispatch(new fromStore.LoadActiveProcesses(body.backendUrl));
          this.store.dispatch(new fromStore.LoadProcessTotals());
        } else if (type === "TASK") {
          if (eventName === "create") {
            this.store.dispatch(new fromStore.LoadOpenTasks(body.backendUrl));
            this.store.dispatch(new fromStore.LoadActiveProcesses(body.backendUrl));
          } else if (eventName === "assignment") {
            this.store.dispatch(new fromStore.LoadOpenTasks(body.backendUrl));
          } else if (eventName === "complete") {
            this.store.dispatch(new fromStore.LoadOpenTasks(body.backendUrl));
            this.store.dispatch(new fromStore.LoadActiveProcesses(body.backendUrl));
          }
          this.store.dispatch(new fromStore.LoadTaskTotals());
        } else if (type === "INCIDENT") {
          this.store.dispatch(new fromStore.LoadIncidents(body.backendUrl));
        }
      } else {
        this.store.dispatch(new fromStore.LoadQuickActions());
      }
    });
    this.subscriptions.push(rxStompSub);

    const routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHome =
          event.url.includes("home") ||
          event.urlAfterRedirects.includes("home") ||
          event.url.includes("legal-notice") ||
          event.urlAfterRedirects.includes("legal-notice")
            ? true
            : false ||
              event.url.includes("data-privacy") ||
              event.urlAfterRedirects.includes("data-privacy")
            ? true
            : false;
        this.offcanvasService.dismiss();
      }
    });
    this.subscriptions.push(routerSub);
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
    // this.router.events
    //   .pipe(
    //     withLatestFrom(this.isHighRes),
    //     filter(([a, b]) => b && a instanceof NavigationEnd),
    //   )
    //   .subscribe((res) => {
    //     let isLowRes = !res[1].matches;
    //     if (isLowRes) {
    //       this.sidenav?.close();
    //     }
    //   });
    this.isLoading$ = this.loadingService.isLoading.pipe(delay(0));
  }

  toggleSideNav() {
    this.sidenav.toggle();
    if (this.isHighResMode) {
      if (this.menuIcon === "chevron_left") {
        this.menuIcon = "menu";
      } else {
        this.menuIcon = "chevron_left";
      }
    }
  }
  closeSidenav() {
    this.sidenav.close();
  }

  doNothing() {
    return;
  }

  isLoggedIn() {
    return this.tokenHelper.isLoggedIn();
  }

  openTop(content: TemplateRef<any>) {
    this.offcanvasService.open(content, { position: "top", panelClass: "height-100" });
  }

  onStartGuidedTour(id: number): void {
    if (id) {
      this.guidedTourService.startTour(this.portalGuidedTourService.getGuidedTour(id));
    }
  }

  private initStore(): void {
    if (this.tokenHelperService.isLoggedIn()) {
      this.store.dispatch(new fromStore.LoadAllOpenTasks());
      this.store.dispatch(new fromStore.LoadAllActiveProcesses());
      this.store.dispatch(new fromStore.LoadEndpoints());
      this.store.dispatch(new fromStore.LoadHelp());

      // Portal
      this.store.dispatch(fromSharedStore.fetchEntraIdGroups());
      this.store.dispatch(fromStore.LoadProfile());
      this.store.dispatch(new fromStore.LoadProcessTotals());
      this.store.dispatch(new fromStore.LoadTaskTotals());
      this.store.dispatch(new fromStore.LoadMyServices());
      this.store.dispatch(new fromStore.LoadQuickActions());
      this.store.dispatch(new fromStore.LoadMyRights());

      // Only admins can query incidents (purely for performance optimization)
      // Same authorization check is performed at the backend

      const incidentsSub = this.store.select(fromSharedStore.isUserAdmin).subscribe((isAdmin) => {
        if (isAdmin) {
          this.store.dispatch(new fromStore.LoadAllIncidents());
        }
      });
      this.subscriptions.push(incidentsSub);

      this.isAuthorized = this.store.select(fromSharedStore.isUserAuthorized);

      const translationsSub = this.store.select(fromStore.getEndpoints).subscribe((endpoints) => {
        endpoints.forEach((endpoint) => this.getTranslations(endpoint));
      });
      this.subscriptions.push(translationsSub);
    }
  }

  private getTranslations(endpoint: Endpoint) {
    this.taskService.getTranslations(endpoint.url).subscribe((resT) => {
      let translations: GematikTranslationDto[] = resT.body;
      for (const translation of translations) {
        this.translationService.set(translation.key, translation.value, translation.lang);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
