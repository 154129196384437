import { HttpClient } from "@angular/common/http";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { CookieHelperService } from "../../helper/cookie-helper.service";

@Component({
  selector: "gem-google-map",
  templateUrl: "./gem-google-map.component.html",
  styleUrls: ["./gem-google-map.component.css"],
})
export class GemGoogleMapComponent implements OnInit, OnChanges {
  @Input() backendUrl: string;
  @Input() language: string;
  @Input() lat: string;
  @Input() lng: string;
  @Input() mapHeight: string;

  safeUrl: SafeUrl;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private cookieHelperService: CookieHelperService,
  ) {}

  ngOnInit(): void {
    this.buildSafeUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildSafeUrl();
  }

  onEditCookies(): void {
    this.cookieHelperService.openCookieSettings(false, false);
  }

  get isGoogleServicesContent(): boolean {
    return this.cookieHelperService.isGoogleServicesContent();
  }

  private buildSafeUrl(): void {
    this.http
      .get(`${this.backendUrl}/public/map/key`, { responseType: "text" })
      .subscribe((apiKey) => {
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${this.lat},${this.lng}&language=${this.language}`,
        );
      });
  }
}
