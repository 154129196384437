import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { OAuthService } from "angular-oauth2-oidc";
import { EnvService } from "gematik-form-library";
import { GemFeedbackComponent } from "gematik-shared";

@Component({
  selector: "footer",
  styleUrls: ["footer.component.scss"],
  template: `
    <div class="d-flex justify-content-center mt-2 w-100">
      <div *ngIf="isExpanded" class="w-100 bd-highlight">
        <a data-testid="signOut" class="gem-link-blue" (click)="onSignOut()">Logout</a>
      </div>
      <div class="flex-shrink-1 bd-highlight">
        <a class="gem-link-blue" (click)="onSignOut()">
          <mat-icon class="nav-icons" svgIcon="gem-logout"></mat-icon>
        </a>
      </div>
    </div>

    <div
      class="d-flex w-100"
      [ngClass]="isExpanded ? 'mt-5' : 'mt-3'"
      [class.justify-content-center]="!isExpanded"
    >
      <a data-testid="feedback" class="gem-link-blue" (click)="onFeedback()">
        <img src="/gematik-shared/assets/icons/feedback-blue.svg" />
      </a>
      <a *ngIf="isExpanded" class="ms-2 gem-link-blue" (click)="onFeedback()">
        {{ "footer.feedback" | translate }}
      </a>
    </div>

    <div
      class="d-flex w-100"
      [ngClass]="isExpanded ? 'mt-2' : 'mt-3'"
      [class.justify-content-center]="!isExpanded"
    >
      <a data-testid="lang" (click)="onLangChange()" class="material-icons gem-link-blue">
        language
      </a>
      <a *ngIf="isExpanded" (click)="onLangChange()" class="ms-2 gem-link-blue">
        {{ lang === "de" ? "English" : "Deutsch" }}
      </a>
    </div>
    <div *ngIf="isExpanded" class="d-flex mt-2 w-100">
      <a data-testid="generalTerms" class="gem-link-dark-grey" (click)="onGeneralTerms()">{{
        "footer.general-terms" | translate
      }}</a>
    </div>
    <div *ngIf="isExpanded" class="d-flex mt-2 w-100">
      <a class="gem-link-dark-grey" (click)="onDataPrivacy()">{{ "footer.privacy" | translate }}</a>
    </div>
    <div *ngIf="isExpanded" class="d-flex mt-2 w-100">
      <a data-testid="legalNotice" class="gem-link-dark-grey" (click)="onLegalNotice()">{{
        "footer.legal-notice" | translate
      }}</a>
    </div>
    <div *ngIf="isExpanded" class="d-flex justify-content-end mt-4 w-100 gem-color-dark-grey fs-6">
      &copy; 2024 gematik GmbH
    </div>
  `,
})
export class FooterComponent {
  @Input() isExpanded: boolean;
  @Output() closeHandsetMenu: EventEmitter<any> = new EventEmitter();

  constructor(
    private translateService: TranslateService,
    private oauthService: OAuthService,
    private env: EnvService,
    private dialog: MatDialog,
  ) {}

  onLangChange() {
    if (this.translateService.currentLang === "de") {
      this.translateService.use("en");
      localStorage.setItem("gem-locale", "en");
    } else {
      this.translateService.use("de");
      localStorage.setItem("gem-locale", "de");
    }
  }

  onSignOut(): void {
    const url = window.location.origin;
    this.oauthService.logOut();
    window.location.href = `https://login.windows.net/${this.env.oauthTennantId}/oauth2/logout?post_logout_redirect_uri=${url}`;
    window.sessionStorage.clear();
    localStorage.clear();
  }

  onGeneralTerms(): void {
    const url: string = `${this.env.uwlUrl}/assets/docs/gematik-general-terms-of-use.pdf`;
    window.open(url);
  }

  onDataPrivacy(): void {
    const url: string = `${this.env.uwlUrl}/data-privacy`;
    window.open(url);
  }

  onLegalNotice(): void {
    const url: string = `${this.env.uwlUrl}/legal-notice`;
    window.open(url);
  }

  onFeedback(): void {
    this.closeHandsetMenu.emit();
    this.dialog.open(GemFeedbackComponent, {
      data: {
        backendUrl: this.env.feedbackBackendUrl,
        application: "Portal",
        component: "Portal Footer",
      },
      width: "800px",
    });
  }

  get lang(): string {
    return this.translateService.currentLang;
  }
}
